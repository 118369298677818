import DropDownItemType from "../types/DropDownItemType";
import TransporterListResType from "../types/res/list/TransporterListResType";

export default class TransporterHelper {

	static getTransporterListResArrForDd(listTransporters: TransporterListResType[]) {

		let finalArr: DropDownItemType[] = [];
		listTransporters.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getTransporterListResForDd(transporter: TransporterListResType): DropDownItemType {
		return {
			id: transporter.id,
			name: transporter.name
		};
	}

	static getTransporterListResMap(itemArr: TransporterListResType[]) {
		let map = new Map<number, TransporterListResType>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

}
import DropDownItemType from "../types/DropDownItemType";
import SupplierListResType from "../types/res/list/SupplierListResType";

export default class SupplierHelper {

	static getSupplierListResArrForDd(listSuppliers: SupplierListResType[]) {

		let finalArr: DropDownItemType[] = [];
		listSuppliers.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getSupplierListResForDd(supplier: SupplierListResType): DropDownItemType {
		return {
			id: supplier.id,
			name: supplier.name
		};
	}

	static getSupplierListResMap(itemArr: SupplierListResType[]) {
		let map = new Map<number, SupplierListResType>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

}
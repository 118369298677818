export default class OperatorEnum {

	public static readonly DIVIDE = new OperatorEnum(1, "Divide", "/");
	public static readonly MULTIPLY = new OperatorEnum(2, "Multiply", "X");

	private constructor(
		public readonly id: number,
		public readonly name: string,
		public readonly symbol: string) {
	}

	static get values(): OperatorEnum[] {
		return [
			this.DIVIDE,
			this.MULTIPLY,
		];
	}

	static getById(id: number): OperatorEnum | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.id === id) {
				return element;
			}
		}
		return null;
	}
}

import DropDownItemType from "../types/DropDownItemType";
import EstimateStatusListResType from "../types/res/list/EstimateStatusListResType";

export default class EstimateStatusHelper {

	static getEstimateStatusListResArrForDd(listEstimateStatus: EstimateStatusListResType[]) {

		let finalArr: DropDownItemType[] = [];
		listEstimateStatus.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getEstimateStatusListResForDd(paymentTerm: EstimateStatusListResType): DropDownItemType {
		return {
			id: paymentTerm.id,
			name: paymentTerm.name
		};
	}

	static getEstimateStatusListResMap(itemArr: EstimateStatusListResType[]) {
		let map = new Map<number, EstimateStatusListResType>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

}
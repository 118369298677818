import DropDownItemType from "../types/DropDownItemType";
import CountryLdto from "../types/res/list/CountryLdto";

export default class CurrencyHelper {

	static getCurrencyArrForDd(countryList: CountryLdto[]) {

		let finalArr: DropDownItemType[] = [];
		countryList.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.currencyName
			});
		});
		return finalArr;

	}

	static getCurrencyForDd(item: CountryLdto): DropDownItemType {
		return {
			id: item.id,
			name: item.currencyName
		};
	}

}
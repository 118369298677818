import MenuResType from "../types/res/MenuResType";

interface MenuButtonWiArrowProps {
	index: number;
	menu: MenuResType;
	openMenuView: (menu: MenuResType) => void;
}

function MenuButtonWiArrow({
	index,
	menu,
	openMenuView,
}: MenuButtonWiArrowProps): JSX.Element {
	return (
		<div className="col-md-4 mb-4 menu-btn-wrapper">
			<div className="menu-btn-container">
				<button
					className="btn btn-secondary menu-btn justify-content-between"
					type="button"
					onClick={() => openMenuView(menu)}
				>
					<span className="d-flex">
						<span className="flex-grow-1">
							<span className="fw-bold">
								<>
									{index + 1}.{" "}
									{menu.displayName === null || menu.displayName === ""
										? menu.name
										: menu.displayName}
								</>
							</span>
							<span className="d-block kbs">CS7</span>
						</span>
						<span className="icon">
							<i className="fa-solid fa-caret-right"></i>
						</span>
					</span>
				</button>
			</div>
		</div>
	);
}

export default MenuButtonWiArrow;


interface TextBoxProps {
	label: string;
	value: string;
	disabled?: boolean;
	onChange: React.Dispatch<React.SetStateAction<string>>;
}

function TextBox({
	label,
	value,
	disabled,
	onChange
}: TextBoxProps) {

	return (
		<div className="row align-items-center mb-3">
			<div className="col-sm-2">
				<label htmlFor="inputPassword6" className="col-form-label">
					{label}
				</label>
			</div>
			<div className="col-sm-10">
				<input
					type="text"
					className="form-control"
					value={value}
					onChange={(e) => onChange(e.target.value)}
					disabled={disabled != null && disabled}
				/>
			</div>
		</div>
	);
}

export default TextBox;

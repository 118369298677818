import DropDownItemType from "../types/DropDownItemType";
import ListVouOthChargeResType from "../types/res/list/ListVouOthChargeResType";

export default class VouOthChHelper {

	static getVouOthChArrForDd(listVouOthCh: ListVouOthChargeResType[]) {

		let finalArr: DropDownItemType[] = [];
		listVouOthCh.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getVouOthChForDd(listVouOthCh: ListVouOthChargeResType): DropDownItemType {
		return {
			id: listVouOthCh.id,
			name: listVouOthCh.name
		};
	}

	static getVouOthChListResMap(itemArr: ListVouOthChargeResType[]) {
		let map = new Map<number, ListVouOthChargeResType>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

}
import DropDownItemType from "../types/DropDownItemType";
import CountryLdto from "../types/res/list/CountryLdto";

export default class CountryHelper {

	static getCountryLdtoDdis(countryList: CountryLdto[]) {

		let finalArr: DropDownItemType[] = [];
		countryList.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getCountryForDd(country: CountryLdto): DropDownItemType {
		return {
			id: country.id,
			name: country.name
		};
	}

	static getCountryLdtoMap(itemArr: CountryLdto[]) {
		let map = new Map<number, CountryLdto>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

}
import DropDownItemType from "../types/DropDownItemType";
import TaxCatListResType from "../types/res/list/TaxCatListResType";

export default class TaxCatHelper {

	static getTaxCatsForDd(listTaxCats: TaxCatListResType[]) {

		let finalArr: DropDownItemType[] = [];
		listTaxCats.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getTaxCatListResForDd(taxCat: TaxCatListResType): DropDownItemType {
		return {
			id: taxCat.id,
			name: taxCat.name
		};
	}

	static getTaxCatListResMap(itemArr: TaxCatListResType[]) {
		let map = new Map<number, TaxCatListResType>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}


}
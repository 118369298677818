import RateTypeEnum from "../enums/RateTypeEnum";
import EstimateItemType from "../types/EstimateItemType";
import ProdForEsimateListResType from "../types/res/list/ProdForEsimateListResType";
import TaxCatListResType from "../types/res/list/TaxCatListResType";
import TaxListResType from "../types/res/list/TaxListResType";
import VouOthChOnItemType from "../types/VouOthChOnItemType";
import TaxHelper from "./TaxHelper";

export default class EstimateItemHelper {

	static calItems(
		isAddTax: boolean,
		isIntra: boolean,
		taxMap: Map<number, TaxListResType>,
		taxCatMap: Map<number, TaxCatListResType>,
		productMap: Map<number, ProdForEsimateListResType>,
		estimateItems: EstimateItemType[],
		estimateOthChOnItemArr: VouOthChOnItemType[],
	) {
		if (estimateItems !== undefined && estimateItems !== null && estimateItems.length > 0) {

			let sumAmtAfterDisc = 0;
			for (let i = 0; i < estimateItems.length; i++) {
				let estimateItem = estimateItems[i];

				let product = productMap.get(estimateItem.productId);

				let taxId: number;

				if (product?.taxCatId === null) {
					taxId = product.taxId;
				}
				else {
					let taxCat = taxCatMap.get(product?.taxCatId!);
					taxId = taxCat?.taxId!;
				}
				estimateItem.derivedTaxId = taxId;

				let tax = taxMap.get(estimateItem.derivedTaxId);

				estimateItem.derivedQxr = estimateItem.qty * estimateItem.rate;

				if (estimateItem.discRate === undefined || estimateItem.discRate === null) {
					estimateItem.derivedDiscA = 0;
				}
				else {
					switch (estimateItem.discRateTypeId) {
						case RateTypeEnum.FLAT.id:
							estimateItem.derivedDiscA = estimateItem.discRate * estimateItem.qty;
							break;
						case RateTypeEnum.PERCENT.id:
							estimateItem.derivedDiscA = estimateItem.discRate * estimateItem.derivedQxr / 100;
							break;
						default:
							break;
					}
				}

				estimateItem.derivedAmtAfterDisc = estimateItem.derivedQxr - estimateItem.derivedDiscA!;

				estimateItem.derivedTaxableAmt = estimateItem.derivedAmtAfterDisc;

				sumAmtAfterDisc = sumAmtAfterDisc + estimateItem.derivedAmtAfterDisc;

				if (isAddTax) {

					estimateItem.derivedTaxId = tax?.id;
					estimateItem.derivedTaxRate = tax?.igst;

				}
			}



			if (estimateOthChOnItemArr !== undefined && estimateOthChOnItemArr !== null && estimateOthChOnItemArr.length > 0) {
				for (let i = 0; i < estimateOthChOnItemArr.length; i++) {
					let vouOthChOnItem = estimateOthChOnItemArr[i];

					vouOthChOnItem.derivedAmt = 0;

					for (let j = 0; j < estimateItems.length; j++) {
						let estimateItem = estimateItems[j];
						this.applyOthChOnItem(sumAmtAfterDisc, estimateItem, vouOthChOnItem);
					}
					if (vouOthChOnItem.rateTypeId === RateTypeEnum.FLAT.id) {
						vouOthChOnItem.derivedAmt = vouOthChOnItem.rate;
					}
				}
			}

			if (isAddTax) {
				for (let i = 0; i < estimateItems.length; i++) {
					let estimateItem = estimateItems[i];

					let taxAmtContainer = TaxHelper.calTaxAmt(
						taxMap.get(estimateItem.derivedTaxId!)!,
						estimateItem.derivedTaxRate!,
						isIntra,
						estimateItem.derivedTaxableAmt!
					)

					estimateItem.derivedCgstA = taxAmtContainer.cgstA;
					estimateItem.derivedSgstA = taxAmtContainer.sgstA;
					estimateItem.derivedIgstA = taxAmtContainer.igstA;
					estimateItem.derivedTaxAmt = taxAmtContainer.cgstA + taxAmtContainer.sgstA + taxAmtContainer.igstA;
					estimateItem.derivedAmt = taxAmtContainer.amtWithTax;
				}
			}



		}

		return estimateItems;
	}

	private static applyOthChOnItem(sumAmtAftDisc: number, estimateItem: EstimateItemType, vouOthChOnItem: VouOthChOnItemType) {

		let itemOthChAmt = 0;

		switch (vouOthChOnItem.rateTypeId) {
			case RateTypeEnum.FLAT.id:
				itemOthChAmt = vouOthChOnItem.rate
					* estimateItem.derivedAmtAfterDisc!
					/ sumAmtAftDisc;
				break;
			case RateTypeEnum.PERCENT.id:
				itemOthChAmt = estimateItem.derivedTaxableAmt!
					* vouOthChOnItem.rate
					/ 100;

				vouOthChOnItem.derivedAmt = vouOthChOnItem.derivedAmt! + itemOthChAmt;
				break;
		}

		estimateItem.derivedTaxableAmt = estimateItem.derivedTaxableAmt! + itemOthChAmt;
	}

}
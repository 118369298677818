import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormControl, Row } from "react-bootstrap";
import MessageDialog from "../../dialogs/MessageDialog";
import RateTypeEnum from "../../enums/RateTypeEnum";
import ProductHelper from "../../helpers/ProductHelper";
import RateTypeHelper from "../../helpers/RateTypeHelper";
import StringHelper2 from "../../helpers/StringHelper2";
import DropDownItemType from "../../types/DropDownItemType";
import EstimateItemType from "../../types/EstimateItemType";
import ProdForEsimateListResType from "../../types/res/list/ProdForEsimateListResType";
import DownshiftSelect from "../DownshiftSelectV2";

interface AddEstimateItemFormSvProps {
	productsMap: Map<number, ProdForEsimateListResType>;
	productsForDd: DropDownItemType[];
	handleItemChange: (addEstimateItem: EstimateItemType) => void;
	currencyConversionRateStr: string;
	id?: number
}

function AddEstimateItemFormSv({
	productsMap,
	productsForDd,
	handleItemChange,
	currencyConversionRateStr,
	id
}: AddEstimateItemFormSvProps): JSX.Element {

	const [qtyCurrent, setQtyCurrent] = useState<number | null>(null);
	const [qtyPendingSales, setPendingSales] = useState<number | null>(null);
	const [qtyPendingEstimate, setPendingEstimate] = useState<number | null>(null);
	const [qtyFinal, setFinal] = useState<number | null>(null);

	const [productId, setProductId] = useState<number | null>(null);
	const [qtyStr, setQtyStr] = useState<string>("");
	const [rateStr, setRateStr] = useState<string>("");
	const [discStr, setDiscStr] = useState<string>("");
	const [discRateTypeId, setDiscRateTypeId] = useState<number | null>(null);
	const [desc, setDesc] = useState<string>("");
	//
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	useEffect(() => {
		async function process() {

		}
		process();
	}, []);

	const handleProdChange = (prodIdLoc: number | null) => {

		let qtyCurrentLoc = null;
		let qtyPendingSalesLoc = null;
		let qtyPendingEstimateLoc = null;
		let qtyFinalLoc = null;
		let rateStrLoc = "";

		if (
			prodIdLoc != null &&
			productsMap != null &&
			productsMap.get(prodIdLoc) != null
		) {

			let product = productsMap.get(prodIdLoc);

			qtyCurrentLoc = product?.qtyCurrent!;
			qtyPendingSalesLoc = product?.pendingQtySales!;
			qtyPendingEstimateLoc = product?.pendingQtyEstimate!;

			if (qtyCurrentLoc === undefined || qtyCurrentLoc === null) {
				qtyCurrentLoc = 0;
			}
			if (qtyPendingSalesLoc === undefined || qtyPendingSalesLoc === null) {
				qtyPendingSalesLoc = 0;
			}
			if (qtyPendingEstimateLoc === undefined || qtyPendingEstimateLoc === null) {
				qtyPendingEstimateLoc = 0;
			}

			qtyFinalLoc = qtyCurrentLoc - qtyPendingSalesLoc - qtyPendingEstimateLoc;

			if (product?.mrp != null) {
				let currencyConversionRateLoc = 1;
				if (currencyConversionRateStr != null
					&& currencyConversionRateStr !== ""
					&& StringHelper2.isNum(currencyConversionRateStr)
				) {
					currencyConversionRateLoc = parseFloat(currencyConversionRateStr);
				}

				rateStrLoc = (product?.mrp / currencyConversionRateLoc!).toString();
			}


		}

		setRateStr(rateStr);
		setQtyCurrent(qtyCurrentLoc);
		setPendingSales(qtyPendingSalesLoc);
		setPendingEstimate(qtyPendingEstimateLoc);
		setFinal(qtyFinalLoc);

		setProductId(prodIdLoc);
	};

	const _handleClickSave = async (event: React.FormEvent<HTMLFormElement>) => {

		event.preventDefault();

		if (!isValid()) {
			return;
		}

	}

	const fillInUi = (/*productResType: EstimateResType*/) => {

	};

	const handleClose = () => setShowMessageDialog(false);
	const handleShow = () => setShowMessageDialog(true);

	const clearBoxes = () => {
		setProductId(-1);
		setQtyStr("");
		setRateStr("");
		setDiscStr("");
		setDiscRateTypeId(-1);
		setDesc("");
	};

	const handleSave = (e: React.MouseEvent) => {

		e.preventDefault();

		if (!isValid()) {
			return;
		}

		let productIdLoc: number = productId!;
		let qtyLoc: number = parseFloat(qtyStr);
		let rateLoc: number = parseFloat(rateStr);
		let discLoc: number | null = null;
		let discRateTypeIdLoc: number | null = null;
		let descLoc: string = desc;

		if (discStr != null
			&& StringHelper2.isNum(discStr)
		) {
			discLoc = parseFloat(discStr);
			discRateTypeIdLoc = discRateTypeId;
		}

		let addEstimateItemLoc: EstimateItemType = {
			productId: productIdLoc,
			desc: descLoc,
			qty: qtyLoc,
			rate: rateLoc,
			discRate: discLoc,
			discRateTypeId: RateTypeEnum.PERCENT.id,
		};

		handleItemChange(addEstimateItemLoc);

		clearBoxes();

	};

	const isValid = (): boolean => {

		if (
			productId === undefined
			|| productId === null
			|| productsMap?.get(productId) === undefined
			|| productsMap?.get(productId) === null
		) {
			setMessageDialogMessage("Select product");
			setShowMessageDialog(true);
			return false;
		}

		if (qtyStr === undefined
			|| qtyStr === null
			|| !StringHelper2.isNum(qtyStr)
		) {
			setMessageDialogMessage("Invalid quantity");
			setShowMessageDialog(true);
			return false;
		}

		if (
			rateStr === undefined
			|| rateStr === null
			|| !StringHelper2.isNum(rateStr)) {
			setMessageDialogMessage("Invalid rate");
			setShowMessageDialog(true);
			return false;
		}

		if (
			discStr != null
			&& !StringHelper2.isNum(discStr)) {
			setMessageDialogMessage("Invalid discount");
			setShowMessageDialog(true);
			return false;
		}

		if (
			discStr != null
			&& (
				discRateTypeId == null
				|| RateTypeEnum.getById(discRateTypeId) == null)
		) {
			setMessageDialogMessage("Invalid discount rate type");
			setShowMessageDialog(true);
			return false;
		}

		return true;
	}

	return (
		<>
			<div className="row g-3 align-items-center mb-3">
				<div className="col-auto">
					<label className="col-form-label">Quantity</label>
				</div>
				<div className="col-auto">
					<input type="text" className="form-control form-control-sm" readOnly={true}
						value={qtyCurrent ? qtyCurrent.toLocaleString('en-IN') : ""}
					/>
				</div>
				<div className="col-auto">
					<label className="col-form-label">-</label>
				</div>
				<div className="col-auto">
					<input type="text" className="form-control form-control-sm" readOnly={true}
						value={qtyPendingSales ? qtyPendingSales.toLocaleString('en-IN') : ""}
					/>
				</div>
				<div className="col-auto">
					<label className="col-form-label">-</label>
				</div>
				<div className="col-auto">
					<input type="text" className="form-control form-control-sm" readOnly={true}
						value={qtyPendingEstimate ? qtyPendingEstimate.toLocaleString('en-IN') : ""}
					/>
				</div>
				<div className="col-auto">
					<label className="col-form-label form-control-sm">=</label>
				</div>
				<div className="col-auto">
					<input type="text" className="form-control" readOnly={true}
						value={qtyFinal ? qtyFinal.toLocaleString('en-IN') : ""}
					/>
				</div>

			</div>

			<Row className="mb-3" xs="auto">
				<Form.Group as={Col}>
					<Form.Label>Product</Form.Label>
					<DownshiftSelect
						inputId={"product"}
						value={productId == null || productsMap.get(productId) == null ? null : ProductHelper.getProductForDd(productsMap?.get(productId)!)}
						onChange={handleProdChange}
						options={productsForDd}
						isLoading={false}
						isDisabled={false}
						placeholder=""
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>&nbsp;</Form.Label>
					<FormControl
						size="sm" as={Button}
					>...</FormControl>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Quantity</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={qtyStr}
						onChange={(e) => setQtyStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Rate</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={rateStr}
						onChange={(e) => setRateStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Discount</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={discStr}
						onChange={(e) => setDiscStr(e.target.value)}
					/>
				</Form.Group>


				<Form.Group as={Col}>
					<Form.Label>Disc. Rate Type</Form.Label>
					<DownshiftSelect
						inputId={"rateType"}
						value={
							discRateTypeId == null ||
								RateTypeEnum.getById(discRateTypeId) == null
								?
								null
								:
								RateTypeHelper.getRateTypeForDd(RateTypeEnum.getById(discRateTypeId)!)
						}
						onChange={setDiscRateTypeId}
						options={RateTypeHelper.getRateTypesForDd()}
						isLoading={false}
						isDisabled={false}
						placeholder=""
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>&nbsp;</Form.Label>
					<FormControl
						size="sm"
						onClick={(e) => handleSave(e)}
						// onClick={(e) => console.log("")}
						as={Button}>Save</FormControl>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>&nbsp;</Form.Label>
					<FormControl
						size="sm"
						as={Button}>Cancel</FormControl>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Description</Form.Label>
					<FormControl
						size="sm"
						type="text"
						onChange={(e) => setDesc(e.target.value)}
					/>
				</Form.Group>
			</Row>

			<MessageDialog
				modalMessage={messageDialogMessage}
				showDialog={showMessageDialog}
				setShowDialog={setShowMessageDialog}
			/>

		</>
	);
}

export default AddEstimateItemFormSv;

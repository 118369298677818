import DropDownItemType from "../types/DropDownItemType";
import ProductCatListResType from "../types/res/list/ProductCatListResType";

export default class ProductCatHelper {

	static getProductCatListForDd(productCatList: ProductCatListResType[]) {

		let finalArr: DropDownItemType[] = [];
		productCatList.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getProductCatForDd(productCat: ProductCatListResType): DropDownItemType {
		return {
			id: productCat.id,
			name: productCat.name
		};
	}

	static getProductCatMap(itemArr: ProductCatListResType[]) {
		let map = new Map<number, ProductCatListResType>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

}

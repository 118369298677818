import DropDownItemType from "../types/DropDownItemType";
import PaymentModeListResType from "../types/res/list/PaymentModeListResType";

export default class PaymentModeHelper {

	static getPaymentModeListResArrForDd(listPaymentModes: PaymentModeListResType[]) {

		let finalArr: DropDownItemType[] = [];
		listPaymentModes.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getPaymentModeListResForDd(paymentMode: PaymentModeListResType): DropDownItemType {
		return {
			id: paymentMode.id,
			name: paymentMode.name
		};
	}

	static getPaymentModeListResMap(itemArr: PaymentModeListResType[]) {
		let map = new Map<number, PaymentModeListResType>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

}
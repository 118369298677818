import RateTypeEnum from "../enums/RateTypeEnum";
import EstimateItemType from "../types/EstimateItemType";
import TaxAmtContainerType from "../types/TaxAmtContainerType";
import TotalAmtContainerType from "../types/TotalAmtContainerType";
import VouOthChNonTaxableType from "../types/VouOthChNonTaxableType";

export default class EstimateHelper {

	static getSumAmtAfterItemDisc(
		estimateItems: EstimateItemType[],
	) {
		if (estimateItems !== undefined && estimateItems !== null && estimateItems.length > 0) {

			let sumAmtAfterDisc = 0;
			for (let i = 0; i < estimateItems.length; i++) {
				let estimateItem = estimateItems[i];

				sumAmtAfterDisc = sumAmtAfterDisc + estimateItem.derivedAmtAfterDisc!;
			}
			return sumAmtAfterDisc;
		}
		return 0;
	}

	static getSumItemTaxableAmt(
		estimateItemArr: EstimateItemType[],
	) {
		if (estimateItemArr !== undefined && estimateItemArr !== null && estimateItemArr.length > 0) {

			let sumItemTaxableAmt = 0;
			for (let i = 0; i < estimateItemArr.length; i++) {
				let estimateItem = estimateItemArr[i];

				sumItemTaxableAmt = sumItemTaxableAmt + estimateItem.derivedTaxableAmt!;
			}
			return sumItemTaxableAmt;
		}
		return 0;
	}

	public static getSumAmtAfterTax(
		estimateItemArr: EstimateItemType[]
	) {

		let sumAmtAfterTax = 0;

		if (estimateItemArr !== undefined && estimateItemArr !== null && estimateItemArr.length > 0) {
			for (let i = 0; i < estimateItemArr.length; i++) {
				let estimateItem = estimateItemArr[i];

				sumAmtAfterTax = sumAmtAfterTax + estimateItem.derivedAmt!;
			}
		}

		return sumAmtAfterTax;
	}


	public static getTaxSum(
		estimateItemArr: EstimateItemType[]
	): TaxAmtContainerType {

		let cgstA = 0;
		let sgstA = 0;
		let igstA = 0;

		if (estimateItemArr !== undefined && estimateItemArr !== null && estimateItemArr.length > 0) {

			for (let i = 0; i < estimateItemArr.length; i++) {
				let estimateItem = estimateItemArr[i];

				cgstA = cgstA + estimateItem.derivedCgstA!;
				sgstA = sgstA + estimateItem.derivedSgstA!;
				igstA = igstA + estimateItem.derivedIgstA!;
			}
		}

		return {
			cgstA: cgstA,
			sgstA: sgstA,
			igstA: igstA,
			amtWithTax: 0,
		};
	}


	public static calOthChNonTax(
		estimateItemArr: EstimateItemType[],
		estimateOthChNonTaxableArr: VouOthChNonTaxableType[]
	) {
		let sumAmtAfterTax = this.getSumAmtAfterTax(estimateItemArr);

		for (let i = 0; i < estimateOthChNonTaxableArr.length; i++) {
			let estimateOthChNonTaxable = estimateOthChNonTaxableArr[i];

			if (estimateOthChNonTaxable.rate == null) {
				estimateOthChNonTaxable.derivedAmt = 0;
			}
			else {
				if (estimateOthChNonTaxable.rateTypeId === RateTypeEnum.FLAT.id) {
					estimateOthChNonTaxable.derivedAmt = estimateOthChNonTaxable.rate;
				}
				else {
					if (estimateOthChNonTaxable.rateTypeId === RateTypeEnum.PERCENT.id) {
						estimateOthChNonTaxable.derivedAmt = sumAmtAfterTax * estimateOthChNonTaxable.rate / 100;
					}
				}
			}
		}
	}

	public static getTotalAmt(
		estimateItemArr: EstimateItemType[],
		estimateOthChNonTaxableArr: VouOthChNonTaxableType[]
	): TotalAmtContainerType {

		let totalAmt = 0;
		let roundOff = 0;
		let amtPayable = 0;


		totalAmt = 0;
		//
		for (let i = 0; i < estimateItemArr.length; i++) {
			let estimateItem = estimateItemArr[i];
			{
				totalAmt = totalAmt + estimateItem.derivedAmt!;
			}
		}

		for (let i = 0; i < estimateOthChNonTaxableArr.length; i++) {
			let estimateOthChNonTaxable = estimateOthChNonTaxableArr[i];
			{
				totalAmt = totalAmt + estimateOthChNonTaxable.derivedAmt!;
			}
		}

		amtPayable = totalAmt;
		roundOff = amtPayable - totalAmt;


		return {
			totalAmt: totalAmt,
			roundOff: roundOff,
			amtPayable: amtPayable,
		};
	}
}
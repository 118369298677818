import React, { useEffect, useState } from "react";
import RateTypeEnum from "../../enums/RateTypeEnum";
import StringHelper2 from "../../helpers/StringHelper2";
import DropDownItemType from "../../types/DropDownItemType";
import EstimateOthChNonTaxableRowHolder from "../../types/EstimateOthChNonTaxableRowHolder";
import ListVouOthChargeResType from "../../types/res/list/ListVouOthChargeResType";
import VouOthChNonTaxableType from "../../types/VouOthChNonTaxableType";
import AddEstimateOthChNonTaxableRowSv from "./AddEstimateOthChNonTaxableRowSv";

interface AddEstimateOthChNonTaxableSvProps {
	vouOthChNonTaxableResMap: Map<number, ListVouOthChargeResType>;
	vouOthChNonTaxableResArrForDd: DropDownItemType[];
	vouOthChNonTaxableArr: VouOthChNonTaxableType[];
	handleOthChNonTaxableChange: (vouOthChNonTaxableArr: VouOthChNonTaxableType[]) => void;
}

function AddEstimateOthChNonTaxableSv({
	vouOthChNonTaxableResMap,
	vouOthChNonTaxableResArrForDd,
	vouOthChNonTaxableArr,
	handleOthChNonTaxableChange
}: AddEstimateOthChNonTaxableSvProps): JSX.Element {

	const [estimateOthChNonTaxableRowHolderArr, setEstimateOthChNonTaxableRowHolderArr] = useState<EstimateOthChNonTaxableRowHolder[]>([]);

	useEffect(() => {

		for (let i = 0; i < estimateOthChNonTaxableRowHolderArr.length; i++) {
			let estimateOthChNonTaxableRowHolder = estimateOthChNonTaxableRowHolderArr[i];

			if (!isValid(estimateOthChNonTaxableRowHolder)) {
				return;
			}
		}

		let vouOthChNonTaxableArrLoc: VouOthChNonTaxableType[] = [];
		for (let i = 0; i < estimateOthChNonTaxableRowHolderArr.length; i++) {
			let estimateOthChNonTaxableRowHolder = estimateOthChNonTaxableRowHolderArr[i];

			vouOthChNonTaxableArrLoc.push({
				othChId: estimateOthChNonTaxableRowHolder.othChId!,
				rate: parseFloat(estimateOthChNonTaxableRowHolder.rateStr!),
				rateTypeId: estimateOthChNonTaxableRowHolder.rateTypeId!
			});
		}

		handleOthChNonTaxableChange(vouOthChNonTaxableArrLoc);

	}, [estimateOthChNonTaxableRowHolderArr]);

	const handleClickAdd = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		event.preventDefault();

		setEstimateOthChNonTaxableRowHolderArr([...estimateOthChNonTaxableRowHolderArr, {}])
	};

	const handleUpdateRow = (index: number, estimateOthChNonTaxableRowHolder: EstimateOthChNonTaxableRowHolder) => {

		let newEstimateOthChNonTaxableRowHolder = [...estimateOthChNonTaxableRowHolderArr];
		newEstimateOthChNonTaxableRowHolder[index] = estimateOthChNonTaxableRowHolder;
		setEstimateOthChNonTaxableRowHolderArr(newEstimateOthChNonTaxableRowHolder);

	};

	const handleClickRemove = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
		event.preventDefault();

		let newEstimateOthChNonTaxableRowHolder = [...estimateOthChNonTaxableRowHolderArr];
		newEstimateOthChNonTaxableRowHolder.splice(index, 1);
		setEstimateOthChNonTaxableRowHolderArr(newEstimateOthChNonTaxableRowHolder);
	};

	const isValid = (estimateOthChNonTaxableRowHolderArr: EstimateOthChNonTaxableRowHolder): boolean => {

		if (
			estimateOthChNonTaxableRowHolderArr.othChId === undefined
			|| estimateOthChNonTaxableRowHolderArr.othChId === null
			|| estimateOthChNonTaxableRowHolderArr.othChId === -1
			|| vouOthChNonTaxableResMap?.get(estimateOthChNonTaxableRowHolderArr.othChId) === undefined
			|| vouOthChNonTaxableResMap?.get(estimateOthChNonTaxableRowHolderArr.othChId) === null
		) {
			return false;
		}

		if (estimateOthChNonTaxableRowHolderArr.rateStr === undefined
			|| estimateOthChNonTaxableRowHolderArr.rateStr === null
			|| !StringHelper2.isNum(estimateOthChNonTaxableRowHolderArr.rateStr)
		) {
			return false;
		}

		if (
			estimateOthChNonTaxableRowHolderArr.rateTypeId === undefined
			|| estimateOthChNonTaxableRowHolderArr.rateTypeId === null
			|| estimateOthChNonTaxableRowHolderArr.rateTypeId === -1
			|| RateTypeEnum.getById(estimateOthChNonTaxableRowHolderArr.rateTypeId) === undefined
			|| RateTypeEnum.getById(estimateOthChNonTaxableRowHolderArr.rateTypeId) === null
		) {
			return false;
		}

		return true;

	}

	return (
		<div className="d-flex g-3 justify-content-end">
			<div className="card">
				<div className="card-header">
					Other harge (After Tax)
				</div>
				<div className="card-body">

					{estimateOthChNonTaxableRowHolderArr.map((estimateOthChNonTaxableRowHolder, index) => (
						<div key={index}>
							<AddEstimateOthChNonTaxableRowSv
								vouOthChNonTaxableResMap={vouOthChNonTaxableResMap}
								vouOthChNonTaxableResArrForDd={vouOthChNonTaxableResArrForDd}
								vouOthChNonTaxable={index < vouOthChNonTaxableArr.length ? vouOthChNonTaxableArr[index] : null}
								estimateOthChNonTaxableRowHolder={estimateOthChNonTaxableRowHolder}
								handleClickRemove={handleClickRemove}
								handleUpdateRow={handleUpdateRow}
								myIndex={index}
								key={index}
							/>
						</div>
					))}

					<a
						onClick={(event) => handleClickAdd(event)}
						href="#"
						className="btn btn-primary">
						Add
					</a>
				</div>
			</div>
		</div>
	);
}

export default AddEstimateOthChNonTaxableSv;

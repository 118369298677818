import MenuResType from "./types/res/MenuResType";

export const BASE_URL: string = process.env.REACT_APP_API_BASE_URL!;
export let TOKEN = { val: "" };
export let MENUS: { val: MenuResType } = { val: null! };

export let MENU_STACK: Array<JSX.Element> = [];
export let TITLE_STACK: string[] = [];

export let CURRENT_LAYOUT: {
	val: JSX.Element;
} = { val: null! };
export let CURRENT_TITLE = { val: "" };

export let LAYOUTS = {
	ADD_TRANSPORT: "ADD_TRANSPORT",
	MENU: "MENU",
};

export let LAYOUTS2 = {
	CommncBook: "commnc_book",
	Reminder: "reminder",
	Commnc: "commnc",
	Lead: "lead",
	AcctBook: "acct_book",
	DayBook: "day_book",
	CdpJournalVou: "cdp_journal_vou",
	Journal: "journal",
	Contra: "contra",
	Receipt: "receipt",
	Payment: "payment",
	SalesReturn: "sales_return",
	Sales: "sales",
	Pi: "pi",
	SalesOrder: "sales_order",
	Estimate: "estimate",
	PurchaseReturn: "purchase_return",
	Purchase: "purchase",
	PurchaseOrder: "purchase_order",
	CommVou: "comm_vou",
	CdpVou: "cdp_vou",
	OrderAdjust: "order_adjust",
	Bank: "bank",
	Customer: "customer",
	Supplier: "supplier",
	Agent: "agent",
	Acct: "acct",
	AcctType: "acct_type",
	InvAdjust: "inv_adjust",
	CommList: "comm_list",
	PurchasePriceList: "purchase_price_list",
	SalesPriceList: "sales_price_list",
	Service: "service",
	Prod: "prod",
	ProdOthCh: "prod_oth_ch",
	TaxCat: "tax_cat",
	Cat: "cat",
	Unit: "unit",
	RptSalesReturnByMonth: "rpt_sales_return_by_month",
	RptSalesReturnByDay: "rpt_sales_return_by_day",
	RptSalesReturnByCustomer: "rpt_sales_return_by_customer",
	RptSalesReturnByCategory: "rpt_sales_return_by_category",
	RptSalesReturnByProduct: "rpt_sales_return_by_product",
	RptSalesReturnByCity: "rpt_sales_return_by_city",
	RptSalesReturnByState: "rpt_sales_return_by_state",
	RptSalesReturnByAgent: "rpt_sales_return_by_agent",
	RptSalesReturnDetailedByVoucher: "rpt_sales_return_detailed_by_voucher",
	RptSalesReturnDetailedByProd: "rpt_sales_return_detailed_by_prod",
	RptSalesByMonth: "rpt_sales_by_month",
	RptSalesByDay: "rpt_sales_by_day",
	RptSalesByCustomer: "rpt_sales_by_customer",
	RptSalesByCategory: "rpt_sales_by_category",
	RptSalesByProduct: "rpt_sales_by_product",
	RptSalesByCity: "rpt_sales_by_city",
	RptSalesByState: "rpt_sales_by_state",
	RptSalesByAgent: "rpt_sales_by_agent",
	RptSalesDetailedByVoucher: "rpt_sales_detailed_by_voucher",
	RptSalesDetailedByProd: "rpt_sales_detailed_by_prod",
	SalesAnalysis: "sales_analysis",
	RptSalesProfitByCustomer: "rpt_sales_profit_by_customer",
	RptSalesProfitByCategory: "rpt_sales_profit_by_category",
	RptSalesProfitByProd: "rpt_sales_profit_by_prod",
	RptSalesProfitBySalesVoucher: "rpt_sales_profit_by_sales_voucher",
	RptSalesProfitBySalesItem: "rpt_sales_profit_by_sales_item",
	RptPurchaseReturnByMonth: "rpt_purchase_return_by_month",
	RptPurchaseReturnByDay: "rpt_purchase_return_by_day",
	RptPurchaseReturnBySupplier: "rpt_purchase_return_by_supplier",
	RptPurchaseReturnByCategory: "rpt_purchase_return_by_category",
	RptPurchaseReturnByProduct: "rpt_purchase_return_by_product",
	RptPurchaseReturnByCity: "rpt_purchase_return_by_city",
	RptPurchaseReturnByState: "rpt_purchase_return_by_state",
	RptPurchaseReturnDetailedByVoucher: "rpt_purchase_return_detailed_by_voucher",
	RptPurchaseReturnDetailedByProd: "rpt_purchase_return_detailed_by_prod",
	RptPurchaseByMonth: "rpt_purchase_by_month",
	RptPurchaseByDay: "rpt_purchase_by_day",
	RptPurchaseBySupplier: "rpt_purchase_by_supplier",
	RptPurchaseByCategory: "rpt_purchase_by_category",
	RptPurchaseByProduct: "rpt_purchase_by_product",
	RptPurchaseByCity: "rpt_purchase_by_city",
	RptPurchaseByState: "rpt_purchase_by_state",
	RptPurchaseDetailedByVoucher: "rpt_purchase_detailed_by_voucher",
	RptPurchaseDetailedByProd: "rpt_purchase_detailed_by_prod",
	RptEstimatePendingByProd: "rpt_estimate_pending_by_prod",
	RptEstimatePendingByAcct: "rpt_estimate_pending_by_acct",
	RptEstimatePendingProducts: "rpt_estimate_pending_products",
	DueRcvableByCustomer: "due_rcvable_by_customer",
	DueRcvableBySales: "due_rcvable_by_sales",
	RptIoSummByProdCat: "rpt_io_summ_by_prod_cat",
	RptIoSummByMonth: "rpt_io_summ_by_month",
	RptIoSummByProd: "rpt_io_summ_by_prod",
	RptIoDet: "rpt_io_det",
	RptInventoryTurnover: "rpt_inventory_turnover",
	Ledger: "ledger",
	AcctSummByAcctType: "acct_summ_by_acct_type",
	TrialBal: "trial_bal",
	TradingAcct: "trading_acct",
	PlAcct: "pl_acct",
	BalanceSheet: "balance_sheet",
	Gstr3b: "gstr3b",
	InventoryLedger: "inventory_ledger",
	CurrentStock: "current_stock",
	CurrentStockVal: "current_stock_val",
	LowStock: "low_stock",
	CdpVouLedger: "cdp_vou_ledger",
	CdpVouLedger2: "cdp_vou_ledger_2",
	CommVouLedger: "comm_vou_ledger",
	SalesOrderPending: "sales_order_pending",
	PurchaseOrderPending: "purchase_order_pending",
	AcctExpenseAnalysis: "acct_expense_analysis",
	MsgQueue: "msg_queue",
	Scheme: "scheme",
	Transporter: "transporter",
	TaxCalRate: "tax_cal_rate",
	Tax: "tax",
	MsgTemplate: "msg_template",
	Browser: "browser",
	CompanyInfo: "company_info",
	CompanyProfile: "company_profile",
	VouOthCh: "vou_oth_ch",
	LeadSrc: "lead_src",
	LeadStatus: "lead_status",
	ImportData: "import_data",
	MenuConfig: "menu_config",
};

import DropDownItemType from "../types/DropDownItemType";
import TaxListResType from "../types/res/list/TaxListResType";
import TaxAmtContainerType from "../types/TaxAmtContainerType";

export default class TaxHelper {


	static getTaxListForDd(taxList: TaxListResType[]) {

		let finalArr: DropDownItemType[] = [];
		taxList.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getTaxForDd(tax: TaxListResType): DropDownItemType {
		return {
			id: tax.id,
			name: tax.name
		};
	}

	static getTaxMap(itemArr: TaxListResType[]) {
		let map = new Map<number, TaxListResType>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

	static calTaxAmt(
		tax: TaxListResType,
		taxRate: number,
		isIntra: boolean,
		taxableAmt: number
	): TaxAmtContainerType {

		let cgstA = 0;
		let sgstA = 0;
		let igstA = 0;
		let amtWithTax = 0;

		if (tax !== undefined && tax !== null && taxableAmt !== undefined && taxableAmt !== null) {

			if (taxRate === undefined || taxRate === null) {
				taxRate = tax.igst;
			}

			let taxAmt = taxableAmt * taxRate / 100;

			if (isIntra) {
				cgstA = taxAmt / 2;
				sgstA = cgstA;
			}
			else {
				igstA = taxAmt;
			}

			amtWithTax = taxableAmt + cgstA + sgstA + igstA;
		}

		return {
			cgstA: cgstA,
			sgstA: sgstA,
			igstA: igstA,
			amtWithTax: amtWithTax
		}

	}

}
import React from "react";
import RateTypeEnum from "../../enums/RateTypeEnum";
import RateTypeHelper from "../../helpers/RateTypeHelper";
import VouOthChHelper from "../../helpers/VouOthChHelper";
import DropDownItemType from "../../types/DropDownItemType";
import EstimateOthChOnItemRowHolder from "../../types/EstimateOthChOnItemRowHolder";
import ListVouOthChargeResType from "../../types/res/list/ListVouOthChargeResType";
import VouOthChOnItemType from "../../types/VouOthChOnItemType";
import DownshiftSelect from "../DownshiftSelectV2";

interface AddEstimateOthChOnItemRowSvProps {
	vouOthChOnItemResMap: Map<number, ListVouOthChargeResType>;
	vouOthChOnItemResArrForDd: DropDownItemType[];
	vouOthChOnItem: VouOthChOnItemType | null;
	estimateOthChOnItemRowHolder: EstimateOthChOnItemRowHolder;
	handleClickRemove: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => void;
	handleUpdateRow: (key: number, estimateOthChOnItemRowHolder: EstimateOthChOnItemRowHolder) => void;
	myIndex: number;
}

function AddEstimateOthChOnItemRowSv({
	vouOthChOnItemResMap,
	vouOthChOnItemResArrForDd,
	vouOthChOnItem,
	estimateOthChOnItemRowHolder,
	handleClickRemove,
	handleUpdateRow,
	myIndex
}: AddEstimateOthChOnItemRowSvProps): JSX.Element {

	return (
		<div className="row g-3 align-items-center">
			<div className="col-auto">
				<button
					onClick={(e) => handleClickRemove(e, myIndex)}
					type="submit"
					className="btn btn-primary mb-3"
				>
					Remove
				</button>
			</div>
			<div className="col-auto">
				<DownshiftSelect
					inputId={"vou_oth_ch_on_item"}
					value={
						estimateOthChOnItemRowHolder.othChId !== undefined &&
							estimateOthChOnItemRowHolder.othChId !== null &&
							vouOthChOnItemResMap.get(estimateOthChOnItemRowHolder.othChId) !== null &&
							vouOthChOnItemResMap.get(estimateOthChOnItemRowHolder.othChId) !== undefined
							?
							VouOthChHelper.getVouOthChForDd(vouOthChOnItemResMap.get(estimateOthChOnItemRowHolder.othChId)!)
							:
							null
					}
					onChange={(e) => handleUpdateRow(
						myIndex,
						{
							othChId: e,
							rateStr: estimateOthChOnItemRowHolder.rateStr,
							rateTypeId: estimateOthChOnItemRowHolder.rateTypeId
						}
					)}
					options={vouOthChOnItemResArrForDd}
					isLoading={false}
					isDisabled={false}
					placeholder=""
				/>
			</div>
			<div className="col-auto">
				<input
					type="text"
					className="form-control"
					aria-describedby="passwordHelpInline"
					value={estimateOthChOnItemRowHolder.rateStr ?? ""}
					onChange={(e) => handleUpdateRow(
						myIndex,
						{
							othChId: estimateOthChOnItemRowHolder.othChId,
							rateStr: e.target.value,
							rateTypeId: estimateOthChOnItemRowHolder.rateTypeId
						}
					)}
				/>
			</div>
			<div className="col-auto">
				<DownshiftSelect
					inputId={"rateType"}
					value={
						estimateOthChOnItemRowHolder.rateTypeId !== null &&
							estimateOthChOnItemRowHolder.rateTypeId !== undefined &&
							RateTypeEnum.getById(estimateOthChOnItemRowHolder.rateTypeId) !== null &&
							RateTypeEnum.getById(estimateOthChOnItemRowHolder.rateTypeId) !== undefined
							?
							RateTypeHelper.getRateTypeForDd(RateTypeEnum.getById(estimateOthChOnItemRowHolder.rateTypeId)!)
							:
							null
					}
					onChange={(e) => handleUpdateRow(
						myIndex,
						{
							othChId: estimateOthChOnItemRowHolder.othChId,
							rateStr: estimateOthChOnItemRowHolder.rateStr,
							rateTypeId: e
						}
					)}
					options={RateTypeHelper.getRateTypesForDd()}
					isLoading={false}
					isDisabled={false}
					placeholder=""
				/>
			</div>
			<div className="col-auto">
				<input
					type="text"
					className="form-control"
					value={vouOthChOnItem?.derivedAmt?.toString() ?? ""}
					readOnly={true}
				/>
			</div>
		</div>
	);
}

export default AddEstimateOthChOnItemRowSv;

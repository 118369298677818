import { BASE_URL, TOKEN } from "../config";
import { ResourceEnum } from "../enums/ResourceEnum";
import { VoucherTypeEnum } from "../enums/VoucherTypeEnum";
import strFormat from "../helpers/StringHelper";
import { getQueryString } from "../helpers/UrlSearchParamHelper";
import NextNoResType from "../types/res/NextNoResType";

export default class VoucherApi {

	static async getNextNo(voucherType: VoucherTypeEnum) {

		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Bearer " + TOKEN.val,
				"Content-Type": "application/json",
			},
		};
		const res = await fetch(
			strFormat("%s/%s/%s", BASE_URL, ResourceEnum.VOUCHER.name, "next-no")
			+ getQueryString({ voucherType: voucherType.enumName.toUpperCase() }),
			requestOptions);

		const data: NextNoResType = await res.json();
		return data.no;
	}


}
import DropDownItemType from "../types/DropDownItemType";
import SalesChannelListResType from "../types/res/list/SalesChannelListResType";

export default class SalesChannelHelper {

	static getSalesChannelListResArrForDd(listSalesChannels: SalesChannelListResType[]) {

		let finalArr: DropDownItemType[] = [];
		listSalesChannels.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getSalesChannelListResForDd(salesChannel: SalesChannelListResType): DropDownItemType {
		return {
			id: salesChannel.id,
			name: salesChannel.name
		};
	}

	static getSalesChannelListResMap(itemArr: SalesChannelListResType[]) {
		let map = new Map<number, SalesChannelListResType>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

}
import { BASE_URL } from "../config";
import ServerNotReachableErrorCommonCudObj from "../default_objects/ServerNotReachableErrorCommonCudObj";
import ServerSideErrorCommonCudObj from "../default_objects/ServerSideErrorCommonCudObj";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import CommonGetOneType from "../types/CommonGetOneType";
import ErrorResType from "../types/res/ErrorResType";
import LoginResDto from "../types/res/LoginResDto";

export default class LoginApi {

	static async login(username: string, password: string): Promise<CommonGetOneType<LoginResDto>> {

		try {
			const requestOptions = {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({ username: username, password: password }),
			};

			const response = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.LOGIN.name), requestOptions);

			if (response.status === 200) {
				const data: LoginResDto = await response.json();
				return {
					isSuccess: true,
					item: data
				};
			}
			else if (response.status === 403) {
				const data: ErrorResType = await response.json();
				return {
					isSuccess: false,
					errorRes: data
				};
			}
			else {
				return ServerSideErrorCommonCudObj;
			}
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}
}

import OperatorEnum from "../enums/OperatorEnum";
import DropDownItemType from "../types/DropDownItemType";

export default class OperatorHelper {

	static getOperatorsForDd() {
		let finalArr: DropDownItemType[] = [];
		OperatorEnum.values.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;
	}

	static getOperatorForDd(operator: OperatorEnum): DropDownItemType {
		return {
			id: operator.id,
			name: operator.name
		};
	}

}
import Cookies from "universal-cookie";
import { TOKEN } from "./config";

export const checkIsLoggedIn = () => {
	const cookies = new Cookies();
	const token = cookies.get("token");

	if (typeof token === "undefined" || token === null || token === "") {
		return false;
	} else {
		TOKEN.val = token;
		return true;
	}
};

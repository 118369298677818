import DropDownItemType from "../types/DropDownItemType";
import PaymentTermListResType from "../types/res/list/PaymentTermListResType";

export default class PaymentTermHelper {

	static getPaymentTermListResArrForDd(listPaymentTerms: PaymentTermListResType[]) {

		let finalArr: DropDownItemType[] = [];
		listPaymentTerms.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getPaymentTermListResForDd(paymentTerm: PaymentTermListResType): DropDownItemType {
		return {
			id: paymentTerm.id,
			name: paymentTerm.name
		};
	}

	static getPaymentTermListResMap(itemArr: PaymentTermListResType[]) {
		let map = new Map<number, PaymentTermListResType>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

}
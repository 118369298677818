import DropDownItemType from "../types/DropDownItemType";
import ProdForEsimateListResType from "../types/res/list/ProdForEsimateListResType";
import ProductListResType from "../types/res/list/ProductListResType";

export default class ProductHelper {

	static getProductsForDd(listProducts: ProdForEsimateListResType[]) {

		let finalArr: DropDownItemType[] = [];
		listProducts.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getProductForDd(product: ProductListResType | ProdForEsimateListResType): DropDownItemType {
		return {
			id: product.id,
			name: product.name
		};
	}

	static getProductMap1(itemArr: ProductListResType[]) {
		let map = new Map<number, ProductListResType>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

	static getProductMap2(itemArr: ProdForEsimateListResType[]) {
		let map = new Map<number, ProdForEsimateListResType>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

}